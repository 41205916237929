import React from 'react'
const baseurl = process.env.REACT_APP_BACKEND_URL

const Event = ({ event, language }) => {
  return (
    <div className='Event'>
      <div className='Event-left'>
        {language === 'fi' && <p className='Event-time'>{event.date} klo {event.time}</p>}
        {language === 'sv' && <p className='Event-time'>{event.date} klockan {event.time}</p>}
        {language === 'en' && <p className='Event-time'>{event.date} at {event.time}</p>}
        <img className='Event-image' src={`${baseurl}/events/image/${event.id}`} alt='event' />
        <p className='Event-name'>{event.name}</p>
      </div>
      <div className='Event-right'>
        <p>{event.place}</p>
        <p>{event.description}</p>
        {language === 'fi' && <p className='Event-speakers'>Puhujat:</p>}
        {language === 'sv' && <p className='Event-speakers'>Högtalare:</p>}
        {language === 'en' && <p className='Event-speakers'>Speakers:</p>}
        <p>{event.speakers}</p>
      </div>
    </div>
  )
}

export default Event
