import React from 'react'

const PublicationList = ({ names, handlePublicationSelection }) => {
  return (
    <>
      {names.map((name) => <div key={name} className='Publication-name-box' onClick={() => handlePublicationSelection(name)}><p>{name}</p></div>)}
    </>
  )
}

export default PublicationList
