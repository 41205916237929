import React, { useEffect, useState } from 'react'
import publicationService from '../../services/publicationService'
import Publication from './Publication'
import PublicationList from './PublicationList'
import arrowIconLeft from '../../resources/arrow-icon-left.png'
import publicationsIcon from '../../resources/publications-icon.png'

const PublicationCategory = ({ category, setCategory, publicationRedirect }) => {

  const [names, setNames] = useState([])
  const [showPublication, setShowPublication] = useState(publicationRedirect)

  useEffect(() => {
    publicationService.getPublicationsByCategory(category)
      .then((res) => setNames(res))
  }, [category])

  const handlePublicationSelection = (name) => {
    setShowPublication(name)
  }

  if (showPublication !== '') {
    return (
      <div>
        <div className='Julkaisut-heading-div'>
          <img className='Back-icon' src={arrowIconLeft} onClick={() => setShowPublication('')} alt='Go back' />
          <h1>{category}</h1>
          <img className='Publication-icon' src={publicationsIcon} alt='open book' />
        </div>
        <div className='Publication-category'>
          <Publication name={showPublication} />
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='Julkaisut-heading-div'>
          <img className='Back-icon' src={arrowIconLeft} onClick={() => setCategory('')} alt='Go back' />
          <h1>{category}</h1>
          <img className='Publication-icon' src={publicationsIcon} alt='open book' />
        </div>
        <div className='Publication-category'>
          <div className='Publication-list'>
            <PublicationList names={names} handlePublicationSelection={handlePublicationSelection} />
          </div>
        </div>
      </div>
    )
  }
}

export default PublicationCategory
