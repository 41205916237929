import React, { useEffect, useState } from 'react'
import memberService from '../../services/memberService'
import hakemusService from '../../services/hakemusService'
import Button from '../subComponents/Button'

const MuokkaaTietoja = ({ cookies, language, setBody }) => {

    const [email, setEmail] = useState('')
    const [sukunimi, setSukunimi] = useState('')
    const [etunimet, setEtunimet] = useState('')
    const [titteli, setTitteli] = useState('')
    const [puhelinnumero, setPuhelinnumero] = useState('')
    const [katuosoite, setKatuosoite] = useState('')
    const [postinumero, setPostinumero] = useState('')
    const [yTunnus, setYtunnus] = useState('')
    const [syntymavuosi, setSyntymavuosi] = useState('')
    const [maa, setMaa] = useState('')
    const [paikkakunta, setPaikkakunta] = useState('')

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const userIdentity = cookies.get('user_identity')
    const userToken = cookies.get('user_token')

    useEffect(() => {
        memberService.getDetails(userIdentity, userToken)
            .then((res) => {
                setEmail(res.email)
                setSukunimi(res.last_name)
                setEtunimet(res.first_names)
                setTitteli(res.title)
                setPuhelinnumero(res.phone_number)
                setKatuosoite(res.address)
                setPostinumero(res.postal_code)
                setYtunnus(res.business_id)
                setSyntymavuosi(res.birthyear)
                setMaa(res.country)
                setPaikkakunta(res.city)
            })
    }, [userIdentity, userToken])

    const send = async () => {
        let validation = hakemusService.validateMembershipForm(language, email, sukunimi, etunimet, titteli, puhelinnumero,
            katuosoite, postinumero, yTunnus, syntymavuosi, maa, paikkakunta)
        if (validation === 'success') {
            const res = await memberService.edit(userIdentity, userToken, email, sukunimi, etunimet, titteli, puhelinnumero,
                katuosoite, postinumero, yTunnus, syntymavuosi, maa, paikkakunta)
            if (res.status === 'success') {
                setError('')
                if (language === 'fi') {
                    setSuccess('Tiedot päivitetty.')
                }
                if (language === 'sv') {
                    setSuccess('Information uppdaterad.')
                }
                if (language === 'en') {
                    setSuccess('Information updated.')
                }
            } else {
                if (language === 'fi') {
                    setError('Jotain meni vikaan. Tietoja ei päivitetty.')
                }
                if (language === 'sv') {
                    setError('Något gick fel. Data uppdaterades inte.')
                }
                if (language === 'en') {
                    setError('Something went wrong. No updates were made.')
                }
            }
        } else {
            setSuccess('')
            setError(validation)
        }
    }

    return (
        <div className='Inner-Container'>
            <div className='Muokkaa-tietoja-container-outer'>
                <div className='Muokkaa-tietoja-container-inner'>

                    {language === 'fi' && <h1 className='Omat-tiedot-header'>Omat tiedot</h1>}
                    {language === 'sv' && <h1 className='Omat-tiedot-header'>Egen information</h1>}
                    {language === 'en' && <h1 className='Omat-tiedot-header'>My information</h1>}

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Sähköposti</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>E-post</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Email</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setEmail(event.target.value)} defaultValue={email} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Sukunimi</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Efternamn</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Last name</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setSukunimi(event.target.value)} defaultValue={sukunimi} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Etunimet</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Förnamn</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>First names</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setEtunimet(event.target.value)} defaultValue={etunimet} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Titteli tai oppiarvo</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Titel eller lärograd</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Title or education</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setTitteli(event.target.value)} defaultValue={titteli} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Puhelinnumero</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Telefonnummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Phone number</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setPuhelinnumero(event.target.value)} defaultValue={puhelinnumero} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Katuosoite</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Adress</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Address</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setKatuosoite(event.target.value)} defaultValue={katuosoite} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Postinumero</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Postnummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Zip code</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setPostinumero(event.target.value)} defaultValue={postinumero} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Y-tunnus</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>FO-nummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Business ID</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setYtunnus(event.target.value)} defaultValue={yTunnus} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Syntymävuosi</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Födelseår</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Year of birth</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setSyntymavuosi(event.target.value)} defaultValue={syntymavuosi} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Maa</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Land</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Country</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setMaa(event.target.value)} defaultValue={maa} />
                    </div>

                    <div className='Omat-tiedot-edit-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Paikkakunta</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Ort</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Locality</label>}
                        <br></br>
                        <input className='Omat-tiedot-input' onChange={(event) => setPaikkakunta(event.target.value)} defaultValue={paikkakunta} />
                    </div>

                    {error !== '' && <p className='Error'>{error}</p>}
                    {success !== '' && <p className='Success'>{success}</p>}

                    {language === 'fi' && <Button type='Omat-tiedot-button' text='Tallenna' handleClick={() => send()} />}
                    {language === 'sv' && <Button type='Omat-tiedot-button' text='Spara' handleClick={() => send()} />}
                    {language === 'en' && <Button type='Omat-tiedot-button' text='Save' handleClick={() => send()} />}
                </div>
            </div>
        </div>
    )
}

export default MuokkaaTietoja
