import React, { useEffect, useState } from 'react'
import publicationService from '../../services/publicationService'

const baseurl = process.env.REACT_APP_BACKEND_URL

const Publication = ({ name }) => {

  const url = `${baseurl}/publications/file/${name}/`

  const [number, setNumber] = useState('')
  const [year, setYear] = useState('')
  const [description, setDescription] = useState('')
  const [filename, setFilename] = useState('')

  useEffect(() => {
    publicationService.getPublication(name)
      .then((data) => {
        setNumber(data.number)
        setYear(data.year)
        setDescription(data.description)
        setFilename(data.publication_file)
      })
  }, [name])

  return (
    <div className='Publication'>
      <h2>{name}</h2>
      <p className='Publication-year'>{number}/{year}</p>
      <p className='Publication-description'>{description}</p>
      <a className='Publication-file' href={url}>{filename}</a>
    </div>
  )

}

export default Publication
