import axios from 'axios'

const baseurl = process.env.REACT_APP_BACKEND_URL

const getProjectNames = () => {
    const request = axios.get(`${baseurl}/projects/names/`)
    return request.then((res) => res.data)
}

const getProject = (name) => {
    const request = axios.get(`${baseurl}/projects/${name}/`)
    return request.then((res) => res.data)
}

const getNewestProject = () => {
    const request = axios.get(`${baseurl}/projects/newest/`)
    return request.then((res) => res.data)
}

export default { getProjectNames, getProject, getNewestProject }
