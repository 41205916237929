import React, { useState } from 'react'
import memberService from '../../services/memberService'
import Button from '../subComponents/Button'

const ResetPassword = ({ language }) => {

    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const reset = async () => {

        if (email.length > 0 && email.includes('@') && email.includes('.')) {
            setError('')
            if (language === 'fi') {
                setSuccess('Pyyntö lähetetty.')
            }
            if (language === 'sv') {
                setSuccess('Begäran skickad.')
            }
            if (language === 'en') {
                setSuccess('Request sent.')
            }
            await memberService.resetPassword(language, email)
        } else {
            setSuccess('')
            if (language === 'fi') {
                setError('Tarkista sähköpostiosoite.')
            }
            if (language === 'sv') {
                setError('Kontrollera e-post.')
            }
            if (language === 'en') {
                setError('Check the email address.')
            }
        }
    }

    return (
        <div className='Inner-Container'>
            <div className='ResetPassword-container'>
                {error !== '' && <p className='Error'>{error}</p>}
                {success !== '' && <p className='Success'>{success}</p>}
                {language === 'fi' && <h2 className='Heading'>Salasanan uusiminen</h2>}
                {language === 'sv' && <h2 className='Heading'>Lösenord återställning</h2>}
                {language === 'en' && <h2 className='Heading'>Password reset</h2>}

                <div className='reset-password-block'>
                    {language === 'fi' && <p>Saat uuden salasanan sähköpostiisi, mikäli syöttämälläsi sähköpostilla löytyy rekisteröity jäsen.</p>}
                    {language === 'sv' && <p>Du kommer att få ett nytt lösenord i din e-post om en registrerad medlem hittas i det e-postmeddelande du anger.</p>}
                    {language === 'en' && <p>New password will be sent to you via email if there is a member registered with the email you have specified.</p>}
                </div>

                <div className='reset-password-block'>
                    {language === 'fi' && <p>Sähköposti</p>}
                    {language === 'sv' && <p>E-post</p>}
                    {language === 'en' && <p>Email</p>}
                    <input className='Login-Input' type="text" value={email} name="Email" onChange={({ target }) => setEmail(target.value)} />
                </div>
                {language === 'fi' && <Button type='Login-Button' text='Lähetä' handleClick={() => reset()}></Button>}
                {language === 'sv' && <Button type='Login-Button' text='Skicka' handleClick={() => reset()}></Button>}
                {language === 'en' && <Button type='Login-Button' text='Send' handleClick={() => reset()}></Button>}
            </div>
        </div>
    )
}

export default ResetPassword
