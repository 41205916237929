import React, { useState } from 'react'
import Button from '../subComponents/Button'
import loginService from '../../services/loginService'

const ExtranetLogin = ({ cookies, setMessage, setBody, language }) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const login = async () => {
        const res = await loginService.login(email, password)
        if (res.user_token && res.user_identity) {
            cookies.set('user_token', res.user_token)
            cookies.set('user_identity', res.user_identity)
            if (language === 'fi') {
                setMessage('Kirjattu sisään.')
            }
            if (language === 'sv') {
                setMessage('Loggat in.')
            }
            if (language === 'en') {
                setMessage('Logged in.')
            }
            setTimeout(() => {
                setMessage('')
            }, 4000)
            setBody('main')
        } else if (res.status === 'bad credentials') {
            if (language === 'fi') {
                setError('Tunnukset eivät kelpaa.')
            }
            if (language === 'sv') {
                setError('Fel användarnamn eller lösenord.')
            }
            if (language === 'en') {
                setError('Bad credentials.')
            }
            setTimeout(() => {
                setError('')
            }, 3000)
        }
    }

    return (
        <div className='Inner-Container'>
            <div className='Login-container'>
                {error !== '' && <p className='Error'>{error}</p>}
                {language === 'fi' && <h2 className='Heading'>Kirjaudu Sisään</h2>}
                {language === 'sv' && <h2 className='Heading'>Logga In</h2>}
                {language === 'en' && <h2 className='Heading'>Login</h2>}
                <div>
                    {language === 'fi' && <p>Sähköposti</p>}
                    {language === 'sv' && <p>E-post</p>}
                    {language === 'en' && <p>Email</p>}
                    <input className='Login-Input' type="text" value={email} name="Email" onChange={({ target }) => setEmail(target.value)} />
                </div>
                <div>
                    {language === 'fi' && <p>Salasana</p>}
                    {language === 'sv' && <p>Lösenord</p>}
                    {language === 'en' && <p>Password</p>}
                    <input className='Login-Input' type="password" value={password} name="Password" onChange={({ target }) => setPassword(target.value)} />
                </div>
                <Button type='Login-Button' text='Login' handleClick={() => login()}></Button>
                {language === 'fi' && <p className='Unohdin-salasanani' onClick={() => setBody('account/reset')}>Unohdin salasanani</p>}
                {language === 'sv' && <p className='Unohdin-salasanani' onClick={() => setBody('account/reset')}>Jag har glömt mitt lösenord</p>}
                {language === 'en' && <p className='Unohdin-salasanani' onClick={() => setBody('account/reset')}>I forgot my password</p>}
            </div>
        </div>
    )
}

export default ExtranetLogin
