import React, { useState, useEffect } from 'react'
import PublicOpinionFiles from './PublicOpinionFiles'
import publicOpinionsService from '../../services/publicOpinionsService'

const PublicOpinion = ({ language, publicOpinion }) => {

  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')
  const [requestFiles, setRequestFiles] = useState([])
  const [files, setFiles] = useState([])

  useEffect(() => {
    publicOpinionsService.getPublicOpinion(publicOpinion)
      .then((data) => {
        setDate(data.date)
        setTitle(data.title)
        setRequestFiles(data.request_files)
        setFiles(data.files)
      })
  }, [publicOpinion])

  return (
    <div className='PublicOpinion'>
      <h2>{title}</h2>
      <p className='PublicOpinion-date'>{date}</p>
      <PublicOpinionFiles language={language} files={files} requestFiles={requestFiles} />
    </div>
  )
}

export default PublicOpinion
