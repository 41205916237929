import axios from 'axios'

const baseurl = process.env.REACT_APP_BACKEND_URL

const getPublicOpinionTitles = () => {
  const request = axios.get(`${baseurl}/public_opinions/titles/`)
  return request.then((res) => res.data)
}

const getPublicOpinion = (title) => {
  const request = axios.get(`${baseurl}/public_opinions/${title}/`)
    return request.then((res) => res.data)
}

const getNewestPublicOpinion = () => {
  const request = axios.get(`${baseurl}/public_opinions/newest/`)
    return request.then((res) => res.data)
}

export default { getPublicOpinionTitles, getPublicOpinion, getNewestPublicOpinion }
