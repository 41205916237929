import React from 'react'
import tsvFI from '../../resources/tsv-fi.png'
import tsvSV from '../../resources/tsv-sv.png'
import tsvEN from '../../resources/tsv-en.png'

const Footer = ({ language }) => {
    return (
        <div className='Footer'>
            {language === 'fi' && <a href='https://tsv.fi/'><img src={tsvFI} className='Footer-img' alt='Tieteellisten seurain valtuuskunta' /></a>}
            {language === 'sv' && <a href='https://tsv.fi/'><img src={tsvSV} className='Footer-img' alt='Tieteellisten seurain valtuuskunta' /></a>}
            {language === 'en' && <a href='https://tsv.fi/'><img src={tsvEN} className='Footer-img' alt='Tieteellisten seurain valtuuskunta' /></a>}
        </div>
    )
}

export default Footer
