import React, { useEffect, useState } from 'react'
import publicationService from '../../services/publicationService'
import publicationsIcon from '../../resources/publications-icon.png'

const NewestPublication = ({ setBody, setpublicationCategoryRedirect, setPublicationRedirect }) => {

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [year, setYear] = useState('')
  const [category, setCategory] = useState('')

  useEffect(() => {
    publicationService.getNewestPublication()
      .then((data) => {
        setName(data.name)
        setNumber(data.number)
        setYear(data.year)
        setCategory(data.category)
      })
  }, [name])

  const handleClick = () => {
    setBody('publications')
    setpublicationCategoryRedirect(category)
    setPublicationRedirect(name)
  }

  return (
    <div className='NewestPublication' onClick={() => handleClick()}>
      <img className='NewestPublication-icon' src={publicationsIcon} alt='open book' />
      <h2>{name}</h2>
      <p className='Publication-year'>{number}/{year}</p>
    </div>
  )

}

export default NewestPublication
