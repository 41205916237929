import React from 'react'
import loginService from '../../../services/loginService'
import Button from '../../subComponents/Button'

const UpperNavBar = ({ setBody, cookies, language, changeLanguage }) => {

    const baseurl = process.env.REACT_APP_BACKEND_URL

    // if logged in
    if (cookies.get('user_token')) {
        return (
            <header className='UpperNavBar'>
                <div className='UpperNavBar-languages'>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('fi')}>FI</p>
                    <p className='UpperNavBar-separator'>|</p>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('sv')}>SV</p>
                    <p className='UpperNavBar-separator'>|</p>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('en')}>EN</p>
                </div>
                <div className='UpperNavBar-right-side-loggedIn'>
                    {language === 'fi' && <p className='UpperNavBar-item right Omat-tiedot' onClick={() => setBody('account')}>Omat tiedot</p>}
                    {language === 'sv' && <p className='UpperNavBar-item right Omat-tiedot' onClick={() => setBody('account')}>Egen information</p>}
                    {language === 'en' && <p className='UpperNavBar-item right Omat-tiedot' onClick={() => setBody('account')}>My information</p>}

                    <a className='UpperNavBar-item right no-underline' href={`${baseurl}/intranet/login`}><p>Intranet</p></a>

                    {language === 'fi' && <Button type='UpperNavBar-button right Logout-button' text='Kirjaudu Ulos' handleClick={() => {
                        loginService.logout(cookies)
                    }}></Button>}
                    {language === 'sv' && <Button type='UpperNavBar-button right Logout-button' text='Logga Ut' handleClick={() => {
                        loginService.logout(cookies)
                    }}></Button>}
                    {language === 'en' && <Button type='UpperNavBar-button right Logout-button' text='Logout' handleClick={() => {
                        loginService.logout(cookies)
                    }}></Button>}
                </div>
            </header>
        )
    } else {
        return (
            <header className='UpperNavBar'>
                <div className='UpperNavBar-languages'>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('fi')}>FI</p>
                    <p className='UpperNavBar-separator'>|</p>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('sv')}>SV</p>
                    <p className='UpperNavBar-separator'>|</p>
                    <p className='UpperNavBar-item' onClick={() => changeLanguage('en')}>EN</p>
                </div>
                <div className='UpperNavBar-right-side'>
                    <p className='UpperNavBar-item right' onClick={() => setBody('login')}>Extranet</p>
                    <a className='UpperNavBar-item right no-underline' href={`${baseurl}/intranet/login`}><p>Intranet</p></a>
                </div>
            </header>
        )
    }
}

export default UpperNavBar
