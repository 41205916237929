import React from 'react'

const PublicationCategoryList = ({ categories, handlePublicationSelection }) => {
  return (
    <>
      {categories.map((category) => <div key={category} className='Publication-category-box' onClick={() => handlePublicationSelection(category)}><p>{category}</p></div>)}
    </>
  )
}

export default PublicationCategoryList
