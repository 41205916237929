import React, { useEffect, useState } from 'react'
import publicationService from '../../services/publicationService'
import PublicationCategory from '../../components/subComponents/PublicationCategory'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import publicationsIcon from '../../resources/publications-icon.png'

import PublicationCategoryList from '../subComponents/PublicationCategoryList'

const Julkaisut = ({ language, publicationCategoryRedirect, publicationRedirect, setPublicationRedirect }) => {

    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(publicationCategoryRedirect)

    useEffect(() => {
        publicationService.getCategories()
            .then((res) => setCategories(res))
    }, [])

    const handlePublicationSelection = (category) => {
        setPublicationRedirect('')
        setCategory(category)
    }

    // makes sure the content has loaded before rendering the view
    if (categories.length === 0) {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        // if category is set, render publications for the spesific category
        // clicking the arrowIconLeft resets the category and the view goes back to the category selection
        if (category !== '') {
            return (
                <div className='Inner-Container'>
                    <div className='Julkaisut-container'>
                        <PublicationCategory category={category} setCategory={setCategory} publicationRedirect={publicationRedirect} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='Inner-Container'>
                    <div className='Julkaisut-container'>
                        <div className='Julkaisut-heading-div'>
                            {language === 'fi' && <h1>Julkaisut</h1>}
                            {language === 'sv' && <h1>Publikationer</h1>}
                            {language === 'en' && <h1>Publications</h1>}
                            <img className='Publication-icon' src={publicationsIcon} alt='open book' />
                        </div>
                        <div className='Julkaisut-category-list'>
                            <PublicationCategoryList categories={categories} handlePublicationSelection={handlePublicationSelection} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Julkaisut
