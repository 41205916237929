import React from 'react'

const ProjectList = ({ projectNames, handleProjectSelection }) => {
  return (
    <>
      {projectNames.map((name) => <div key={name} className='ProjectList-box' onClick={() => handleProjectSelection(name)}><p>{name}</p></div>)}
    </>
  )
}

export default ProjectList
