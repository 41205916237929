import React from 'react'

const PublicOpinionFiles = ({ language, files, requestFiles }) => {

  const baseurl = process.env.REACT_APP_BACKEND_URL

  return (
    <>
      {language === 'fi' && <p>Lausuntotiedostot:</p>}
      {language === 'sv' && <p>Utlåtandefiler:</p>}
      {language === 'en' && <p>Statement files:</p>}
      {files.map((filename) => <div key={filename}><a href={`${baseurl}/public_opinions/file/${filename}/`}><p className='PublicOpinion-file'>{filename}</p></a></div>)}
      {language === 'fi' && <p>Lausuntopyyntö ja taustatiedot:</p>}
      {language === 'sv' && <p>Begäran om utlåtande:</p>}
      {language === 'en' && <p>Request for a statement:</p>}
      {requestFiles.map((filename) => <div key={filename}><a href={`${baseurl}/public_opinion_request/file/${filename}/`}><p className='PublicOpinion-request-file'>{filename}</p></a></div>)}
    </>
  )
}

export default PublicOpinionFiles
