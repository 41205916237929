import React, { useEffect, useState } from 'react'
import publicOpinionsService from '../../services/publicOpinionsService'
import PublicOpinion from '../subComponents/PublicOpinion'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import PublicOpinionList from '../subComponents/PublicOpinionList'
import publicOpinionIcon from '../../resources/public-opinion-icon.png'
import arrowLeft from '../../resources/arrow-icon-left.png'

const Lausunnot = ({ language, publicOpinionRedirect }) => {

    const [publicOpinions, setPublicOpinions] = useState([])
    const [viewPublicOpinion, setViewPublicOpinion] = useState(publicOpinionRedirect)

    useEffect(() => {
        publicOpinionsService.getPublicOpinionTitles()
            .then((data) => setPublicOpinions(data))
    }, [])

    const handlePublicOpinionSelection = (title) => {
        setViewPublicOpinion(title)
    }

    // makes sure the content has loaded before rendering the view
    if (publicOpinions.length === 0) {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        // when user selects a public opinion, viewPublicOpinion will be passed and only that public opinion will be rendered
        if (viewPublicOpinion !== '') {
            return (
                <div className='Inner-Container'>
                    <div className='Lausunnot-container'>
                        <div className='Lausunnot-heading-div'>
                            <img className='Back-icon' src={arrowLeft} alt='go back' onClick={() => setViewPublicOpinion('')} />
                            {language === 'fi' && <h1>Lausunnot</h1>}
                            {language === 'sv' && <h1>Utlåtanden</h1>}
                            {language === 'en' && <h1>Statements</h1>}
                            <img className='PublicOpinion-icon' src={publicOpinionIcon} alt='speak bubble' />
                        </div>
                        <PublicOpinion language={language} publicOpinion={viewPublicOpinion} setViewPublicOpinion={setViewPublicOpinion} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='Inner-Container'>
                    <div className='Lausunnot-container'>
                        <div className='Lausunnot-heading-div'>
                            {language === 'fi' && <h1>Lausunnot</h1>}
                            {language === 'sv' && <h1>Utlåtanden</h1>}
                            {language === 'en' && <h1>Statements</h1>}
                            <img className='PublicOpinion-icon' src={publicOpinionIcon} alt='speak bubble' />
                        </div>
                        <div className='PublicOpinionList'>
                            <PublicOpinionList publicOpinions={publicOpinions} handlePublicOpinionSelection={handlePublicOpinionSelection} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Lausunnot
