import axios from 'axios'

const baseurl = process.env.REACT_APP_BACKEND_URL

const getEvents = (language) => {
    const request = axios.get(`${baseurl}/events/${language}`)
    return request.then((res) => res.data)
}

const getNewestEvent = (language) => {
    const request = axios.get(`${baseurl}/events/${language}/newest/`)
    return request.then((res) => res.data)
}

const getEventYears = () => {
    const request = axios.get(`${baseurl}/events/years/`)
    return request.then((res) => res.data)
}

const getEventsByYear = (language, year) => {
    const request = axios.get(`${baseurl}/events/${language}/${year}/`)
    return request.then((res) => res.data)
}

export default { getEvents, getEventYears, getEventsByYear, getNewestEvent }
