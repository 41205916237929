import React, { useEffect, useState } from 'react'
import projectService from '../../services/projectService'
import ProjectFiles from './ProjectFiles'

const Project = ({ language, project }) => {

  const [name, setName] = useState('')
  const [year, setYear] = useState('')
  const [description, setDescription] = useState('')
  const [filenames, setFilenames] = useState([])

  useEffect(() => {
    projectService.getProject(project)
      .then((data) => {
        setName(data.name)
        setYear(data.year)
        setDescription(data.description)
        setFilenames(data.project_files)
      })
  }, [project])

  return (
    <div className='Project'>
      <h2>{name}</h2>
      {language === 'fi' && <p className='Project-year'>Hankkeen aloitusvuosi: {year}</p>}
      {language === 'sv' && <p className='Project-year'>Projektets startår: {year}</p>}
      {language === 'en' && <p className='Project-year'>Started: {year}</p>}
      <div className='Project-description'>
        <p>{description}</p>
      </div>
      <ProjectFiles projectFiles={filenames} />
    </div>
  )
}

export default Project
