import React, { useEffect, useState } from 'react'
import projectService from '../../services/projectService'
import ProjectList from '../subComponents/ProjectList'
import Project from '../subComponents/Project'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import projectsIcon from '../../resources/projects-icon.png'
import arrowLeft from '../../resources/arrow-icon-left.png'

const Hankkeet = ({ language, projectRedirect }) => {

    const [projectNames, setProjectNames] = useState([])
    const [viewProject, setViewProject] = useState(projectRedirect)

    useEffect(() => {
        projectService.getProjectNames()
            .then((data) => setProjectNames(data))
    }, [])

    const handleProjectSelection = (name) => {
        setViewProject(name)
    }

    // makes sure the content has loaded before rendering the view
    if (projectNames.length === 0) {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        // when a user selects a project, viewProject will be passed and only that project will be rendered
        if (viewProject !== '') {
            return (
                <div className='Inner-Container'>
                    <div className='Hankkeet-container'>
                        <div className='Hankkeet-heading-div'>
                            <img className='Back-icon' src={arrowLeft} alt='go back' onClick={() => setViewProject('')} />
                            {language === 'fi' && <h1>Hankkeet</h1>}
                            {language === 'sv' && <h1>Projekter</h1>}
                            {language === 'en' && <h1>Projects</h1>}
                            <img className='Project-icon' src={projectsIcon} alt='a pen and a clipboard' />
                        </div>
                        <Project language={language} project={viewProject} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='Inner-Container'>
                    <div className='Hankkeet-container'>
                        <div className='Hankkeet-heading-div'>
                            {language === 'fi' && <h1>Hankkeet</h1>}
                            {language === 'sv' && <h1>Projekter</h1>}
                            {language === 'en' && <h1>Projects</h1>}
                            <img className='Project-icon' src={projectsIcon} alt='a pen and a clipboard' />
                        </div>
                        <div className='ProjectList'>
                            <ProjectList projectNames={projectNames} handleProjectSelection={handleProjectSelection} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Hankkeet
