import React, { useEffect, useState } from 'react'
import projectService from '../../services/projectService'
import projectsIcon from '../../resources/projects-icon.png'

const NewestProject = ({ language, setBody, setProjectRedirect }) => {

  const [name, setName] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    projectService.getNewestProject()
      .then((data) => {
        setName(data.name)
        setYear(data.year)
      })
  }, [])

  const handleClick = () => {
    setBody('projects')
    setProjectRedirect(name)
  }

  return (
    <div className='NewestProject' onClick={() => handleClick()}>
      <img className='NewestProject-icon' src={projectsIcon} alt='a pen and a clipboard' />
      <h2>{name}</h2>
      {language === 'fi' && <p className='Project-year'>Hankkeen aloitusvuosi: {year}</p>}
      {language === 'sv' && <p className='Project-year'>Projektets startår: {year}</p>}
      {language === 'en' && <p className='Project-year'>Started: {year}</p>}
    </div>
  )
}

export default NewestProject
