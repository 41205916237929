import React, { useEffect, useState } from 'react'
import backgroundAnimation from '../../resources/tieverkostoanimaatio.gif'
import etusivuService from '../../services/etusivuService'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import { FacebookProvider, Page } from 'react-facebook'
import TwitterContainer from '../subComponents/TwitterContainer'
import NewestProject from '../subComponents/NewestProject'
import NewestPublication from '../subComponents/NewestPublication'
import NewestPublicOpinion from '../subComponents/NewestPublicOpinion'
import NewestEvent from '../subComponents/NewestEvent'

const Etusivu = ({ language, setBody, setProjectRedirect, setpublicationCategoryRedirect, setPublicationRedirect, setPublicOpinionRedirect, setEventRedirect }) => {

    const [title, setTitle] = useState('')
    const [paragraph, setParagraph] = useState('')

    useEffect(() => {
        etusivuService.getEtusivu(language)
            .then((data) => {
                setTitle(data.title)
                setParagraph(data.paragraph)
            })
    }, [language])

    // makes sure the content has loaded before rendering the view
    if (title === '') {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        return (
            <div className='Inner-Container'>
                <div className='Etusivu-flexbox-outer'>
                    <div className='Etusivu-flexbox-inner'>
                        <div className='Etusivu-text-box'>
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                        </div>
                        <img className='Background-animation' src={backgroundAnimation} alt='animation of green dots moving on a city map' />
                        <div className='Etusivu-uusimmat'>
                            {language === 'fi' && <h1>Uusimmat</h1>}
                            {language === 'sv' && <h1>Det senaste</h1>}
                            {language === 'en' && <h1>The latest</h1>}
                        </div>
                        <NewestProject language={language} setBody={setBody} setProjectRedirect={setProjectRedirect} />
                        <NewestPublication setBody={setBody} setpublicationCategoryRedirect={setpublicationCategoryRedirect}
                            setPublicationRedirect={setPublicationRedirect} />
                        <NewestPublicOpinion setBody={setBody} setPublicOpinionRedirect={setPublicOpinionRedirect} />
                        <NewestEvent language={language} setBody={setBody} setEventRedirect={setEventRedirect} />
                        <div className='Etusivu-filler'></div>
                        <TwitterContainer />
                        <div className='Etusivu-facebook-container'>
                            <FacebookProvider appId="1002828950147512">
                                <Page href="https://www.facebook.com/liikenneseura" tabs="timeline" />
                            </FacebookProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Etusivu
