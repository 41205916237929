import React, { useEffect, useState } from 'react'
import eventService from '../../services/eventService'
import Event from '../../components/subComponents/Event'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import backArrow from '../../resources/arrow-icon-left.png'
import eventsIcon from '../../resources/events-icon.png'

const EventsByYear = ({ language, year, setShowYear }) => {

    const [events, setEvents] = useState([])

    useEffect(() => {
        eventService.getEventsByYear(language, year)
            .then((res) => setEvents(res))
    }, [language, year])

    // makes sure the content has loaded before rendering the view
    if (events.length === 0) {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        return (
            <div className='Inner-Container'>
                <div className='EventsByYear-container'>
                    <div>
                        <div className='EventsByYear-heading-div'>
                            <img src={backArrow} className='Back-icon' alt='go back' onClick={() => setShowYear('')} />
                            {language === 'fi' && <h1 className='Etusivu-title'>Tapahtumat</h1>}
                            {language === 'sv' && <h1 className='Etusivu-title'>Händelser</h1>}
                            {language === 'en' && <h1 className='Etusivu-title'>Events</h1>}
                            <img className='Event-icon' src={eventsIcon} alt='eventful icon' />
                        </div>
                        {events.map((event) => <Event key={event.id} event={event} language={language} />)}
                    </div>
                </div>
            </div>
        )
    }
}

export default EventsByYear
