import React, { useState, useEffect } from 'react'
import publicOpinionsService from '../../services/publicOpinionsService'
import publicOpinionIcon from '../../resources/public-opinion-icon.png'

const NewestPublicOpinion = ({ setBody, setPublicOpinionRedirect }) => {

  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    publicOpinionsService.getNewestPublicOpinion()
      .then((data) => {
        setDate(data.date)
        setTitle(data.title)
      })
  }, [])

  const handleClick = () => {
    setBody('publicOpinions')
    setPublicOpinionRedirect(title)
  }

  return (
    <div className='NewestPublicOpinion' onClick={() => handleClick()}>
      <img className='NewestPublicOpinion-icon' src={publicOpinionIcon} alt='speak bubble' />
      <h2>{title}</h2>
      <p className='PublicOpinion-date'>{date}</p>
    </div>
  )
}

export default NewestPublicOpinion
