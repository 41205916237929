import React, { useState, useEffect } from 'react'
import './App.css'
import { Switch, Route, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Header from './components/subComponents/navbar/Header'
import Etusivu from './components/mainViews/Etusivu'
import Footer from './components/subComponents/Footer'
import Yhdistyksesta from './components/mainViews/Yhdistyksesta'
import ExtranetLogin from './components/mainViews/ExtranetLogin'
import Julkaisut from './components/mainViews/Julkaisut'
import Tapahtumat from './components/mainViews/Tapahtumat'
import Hankkeet from './components/mainViews/Hankkeet'
import Lausunnot from './components/mainViews/Lausunnot'
import OmatTiedot from './components/mainViews/OmatTiedot'
import MuokkaaTietoja from './components/mainViews/MuokkaaTietoja'
import ResetPassword from './components/mainViews/ResetPassword'
import JasenHakemus from './components/mainViews/JasenHakemus'

const App = () => {

  const cookies = Cookies

  const [language, setLanguage] = useState('fi')
  const [message, setMessage] = useState('')

  const [projectRedirect, setProjectRedirect] = useState('')
  const [publicationCategoryRedirect, setpublicationCategoryRedirect] = useState('')
  const [publicationRedirect, setPublicationRedirect] = useState('')
  const [publicOpinionRedirect, setPublicOpinionRedirect] = useState('')
  const [eventRedirect, setEventRedirect] = useState('')

  const changeLanguage = (language) => {
    setLanguage(language)
  }

  const views = {}

  const history = useHistory()

  const setView = (view) => {
    history.push(`/${view}`)
  }

  useEffect(() => {
    setTimeout(() => {
      setMessage('')
    }, 3000)
  }, [setMessage])

  views['main'] = <Etusivu language={language} setBody={setView} setProjectRedirect={setProjectRedirect}
    setpublicationCategoryRedirect={setpublicationCategoryRedirect} setPublicationRedirect={setPublicationRedirect}
    setPublicOpinionRedirect={setPublicOpinionRedirect} setEventRedirect={setEventRedirect} />

  views['account'] = <OmatTiedot cookies={cookies} language={language} setBody={setView} />

  views['account/edit'] = <MuokkaaTietoja cookies={cookies} language={language} setBody={setView} />

  views['about'] = <Yhdistyksesta language={language} setBody={setView} />

  views['membershipApplication'] = <JasenHakemus language={language} />

  views['publications'] = <Julkaisut language={language} publicationCategoryRedirect={publicationCategoryRedirect}
    publicationRedirect={publicationRedirect} setPublicationRedirect={setPublicationRedirect} />

  views['events'] = <Tapahtumat language={language} eventRedirect={eventRedirect} />

  views['projects'] = <Hankkeet language={language} projectRedirect={projectRedirect} />

  views['publicOpinions'] = <Lausunnot language={language} publicOpinionRedirect={publicOpinionRedirect} />

  views['login'] = <ExtranetLogin cookies={cookies} setBody={setView} setMessage={setMessage} language={language} />

  views['account/reset'] = <ResetPassword language={language} />

  return (
    <div className='App'>
      <Header setBody={setView} cookies={cookies} language={language} changeLanguage={changeLanguage} />
      <div className='Body'>
        {message !== '' && <p className='Success'>{message}</p>}
        <Switch>

          <Route exact path='/'>
            {views['main']}
          </Route>

          <Route exact path='/main'>
            {views['main']}
          </Route>

          <Route exact path='/account'>
            {views['account']}
          </Route>

          <Route exact path='/account/edit'>
            {views['account/edit']}
          </Route>

          <Route exact path='/about'>
            {views['about']}
          </Route>

          <Route exact path='/membershipApplication'>
            {views['membershipApplication']}
          </Route>

          <Route exact path='/publications'>
            {views['publications']}
          </Route>

          <Route exact path='/events'>
            {views['events']}
          </Route>

          <Route exact path='/projects'>
            {views['projects']}
          </Route>

          <Route exact path='/publicOpinions'>
            {views['publicOpinions']}
          </Route>

          <Route exact path='/login'>
            {views['login']}
          </Route>

          <Route exact path='/account/reset'>
            {views['account/reset']}
          </Route>
        </Switch>
      </div>
      <Footer language={language} />
    </div>
  )
}

export default App
