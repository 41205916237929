import React, { useEffect } from "react"

const TwitterContainer = () => {
  useEffect(() => {
    const anchor = document.createElement("a")
    anchor.setAttribute("class", "twitter-timeline")
    anchor.setAttribute("data-theme", "light")
    anchor.setAttribute("data-tweet-limit", "2")
    anchor.setAttribute("data-chrome", "noheader nofooter noborders")
    anchor.setAttribute("href", "https://twitter.com/Liikenne_lss")
    document.getElementsByClassName("twitter-embed")[0].appendChild(anchor)

    const script = document.createElement("script")
    script.setAttribute("src", "https://platform.twitter.com/widgets.js")
    document.getElementsByClassName("twitter-embed")[0].appendChild(script)
  }, []);

  return (
    <section className="Etusivu-twitter-container">
      <div className="twitter-embed"></div>
    </section>
  )
}

export default TwitterContainer
