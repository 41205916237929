import axios from 'axios'

const querystring = require('querystring')

const baseurl = process.env.REACT_APP_BACKEND_URL

const getDetails = (userIdentity, userToken) => {
    const request = axios.post(`${baseurl}/extranet/members/details/`, querystring.stringify({ user_identity: userIdentity, user_token: userToken }))
    return request.then((res) => res.data)
}

const edit = (userIdentity, userToken, email, sukunimi, etunimet, titteli, puhelinnumero, katuosoite, postinumero, yTunnus, syntymavuosi, maa, paikkakunta) => {
    const request = axios.post(`${baseurl}/extranet/members/details/edit/`, querystring.stringify({
        user_identity: userIdentity,
        user_token: userToken,
        email: email,
        last_name: sukunimi,
        first_names: etunimet,
        title: titteli,
        phone_number: puhelinnumero,
        address: katuosoite,
        postal_code: postinumero,
        business_id: yTunnus,
        birthyear: syntymavuosi,
        country: maa,
        city: paikkakunta
    }))
    return request.then((res) => res.data)
}

const resetPassword = (language, email) => {
    const request = axios.post(`${baseurl}/extranet/members/reset/password/`, querystring.stringify({ language: language, email: email }))
    return request.then((res) => res.data)
}

export default { getDetails, edit, resetPassword }
