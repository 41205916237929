import React, { useState } from 'react'
import Button from '../subComponents/Button'
import hakemusService from '../../services/hakemusService'

const JasenHakemus = ({ language }) => {

    const [email, setEmail] = useState('')
    const [sukunimi, setSukunimi] = useState('')
    const [etunimet, setEtunimet] = useState('')
    const [titteli, setTitteli] = useState('')
    const [puhelinnumero, setPuhelinnumero] = useState('')
    const [katuosoite, setKatuosoite] = useState('')
    const [postinumero, setPostinumero] = useState('')
    const [yTunnus, setYTunnus] = useState('')
    const [syntymavuosi, setSyntymavuosi] = useState('')
    const [maa, setMaa] = useState('')
    const [paikkakunta, setPaikkakunta] = useState('')

    const [hyvaksyn, setHyvaksyn] = useState(false)

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const haeJaseneksi = async () => {
        if (hyvaksyn === true) {
            let validation = hakemusService.validateMembershipForm(language, email, sukunimi, etunimet, titteli, puhelinnumero,
                katuosoite, postinumero, yTunnus, syntymavuosi, maa, paikkakunta)
            if (validation === 'success') {
                if (language === 'fi') {
                    setSuccess('Jäsenhakemus lähetetty')
                }
                if (language === 'sv') {
                    setSuccess('Ansökan om medlemskap inlämnad.')
                }
                if (language === 'en') {
                    setSuccess('Membership application sent.')
                }
                await hakemusService.sendMemberApplication(language, email, sukunimi, etunimet, titteli, puhelinnumero, katuosoite,
                    postinumero, yTunnus, syntymavuosi, maa, paikkakunta)
            } else {
                setError(validation)
            }
        } else {
            if (language === 'fi') {
                setError('Sääntöjä ei hyväksytty.')
            }
            if (language === 'sv') {
                setError('Reglerna accepterades inte.')
            }
            if (language === 'en') {
                setError('Rules were not accepted.')
            }
            setTimeout(() => {
                setError('')
            }, 4000)
        }
    }

    const accept = () => {
        hyvaksyn ? setHyvaksyn(false) : setHyvaksyn(true)
    }

    return (
        <div className='Inner-Container'>
            <div className='Jasenhakemus-container'>
                <div className='Hakemus-container-left'>
                    <div className='Inner-Container'>
                        {language === 'fi' && <h1 className='Header-hakemus'>Liity jäseneksi</h1>}
                        {language === 'sv' && <h1 className='Header-hakemus'>Bli medlem</h1>}
                        {language === 'en' && <h1 className='Header-hakemus'>Become a member</h1>}
                        <ol className='hakemus-form'>
                            {language === 'fi' && <h2 className='Hakemus-header'>Jäsenhakemuksen vaiheet:</h2>}
                            {language === 'sv' && <h2 className='Hakemus-header'>Stadier i ansökan om medlemskap:</h2>}
                            {language === 'en' && <h2 className='Hakemus-header'>Steps of applying for membership:</h2>}

                            {language === 'fi' && <li className='hakemus-li'> Lue säännöt ja tietosuojalauseke.</li>}
                            {language === 'sv' && <li className='hakemus-li'> Läs reglerna och dataskyddsbeskrivningen.</li>}
                            {language === 'sv' && <li className='hakemus-li'> Please read the statutes and the Data Protection Statement.</li>}

                            {language === 'fi' && <li className='hakemus-li'> Täytä ja lähetä jäsenhakemuslomake.</li>}
                            {language === 'sv' && <li className='hakemus-li'> Fyll i och sänd formuläret.</li>}
                            {language === 'en' && <li className='hakemus-li'> Fill in and send the form.</li>}

                            {language === 'fi' && <li className='hakemus-li'> Saat lähetetystä hakemuksestasi kuittauksen sähköpostiisi.</li>}
                            {language === 'sv' && <li className='hakemus-li'> Du får en kvittering till din epost.</li>}
                            {language === 'en' && <li className='hakemus-li'> You will get a notification to your email.</li>}

                            {language === 'fi' && <li className='hakemus-li'> Hallituksen käsiteltyä hakemuksesi saat sähköpostitse ohjeet jäsenmaksun
                                maksamisesta. Kun olet suorittanut sen, olet jäsen.</li>}
                            {language === 'sv' && <li className='hakemus-li'> Efter att styrelsen har behandlat din ansökan får du till din epost anvisningar om betalning av
                                medlemsavgiften, och när du har betalat den är du medlem.</li>}
                            {language === 'en' && <li className='hakemus-li'> Membership fee payment instructions will be sent to you via email after the board has handled your application.
                                You will become a member as soon as the payment has been made.</li>}
                        </ol>

                        <div className='hakemus-send'>
                            {error !== '' && <p className='Error'>{error}</p>}
                            {success !== '' && <p className='Success'>{success}</p>}
                            <div className='tietosuoja-container'>
                                <div className='tietosuoja-container-left'>
                                    <input type='checkbox' onChange={() => accept()} />
                                </div>
                                <div className='tietosuoja-container-right'>
                                    {language === 'fi' && <label>Hyväksyn yhdistyksen <a href={process.env.REACT_APP_BACKEND_URL + 'rules/'}>säännöt</a> ja yhdistyksen tarkoituksen
                                        ja tavoitteet ja olen lukenut <a href={process.env.REACT_APP_BACKEND_URL + 'privacy_policy/'}>tietosuojalausekkeen</a>.</label>}
                                    {language === 'sv' && <label>Jag godkänner föreningens <a href={process.env.REACT_APP_BACKEND_URL + 'rules/'}>regler</a> och syften och har
                                        läst <a href={process.env.REACT_APP_BACKEND_URL + 'privacy_policy/'}>dataskyddsbeskrivningen</a>.</label>}
                                    {language === 'en' && <label>I accept the <a href={process.env.REACT_APP_BACKEND_URL + 'rules/'}>statutes</a> and the purpose of the association,
                                        and I have read the <a href={process.env.REACT_APP_BACKEND_URL + 'privacy_policy/'}>data protection statement</a>.</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Hakemus-container-right'>
                    <form>
                        <div>
                            {language === 'fi' && <label className='hakemus-label'>Virallinen nimi*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Officiell namn*</label>}
                            {language === 'en' && <label className='hakemus-label'>Legal name*</label>}
                            <div className='hakemus-inline'>
                                {language === 'fi' && <input className='hakemus-input etunimi' placeholder='Etunimet' value={etunimet} onChange={(event) => setEtunimet(event.target.value)} />}
                                {language === 'sv' && <input className='hakemus-input etunimi' placeholder='Förnamn' value={etunimet} onChange={(event) => setEtunimet(event.target.value)} />}
                                {language === 'en' && <input className='hakemus-input etunimi' placeholder='Firstnames' value={etunimet} onChange={(event) => setEtunimet(event.target.value)} />}

                                {language === 'fi' && <input className='hakemus-input sukunimi' placeholder='Sukunimi' value={sukunimi} onChange={(event) => setSukunimi(event.target.value)} />}
                                {language === 'sv' && <input className='hakemus-input sukunimi' placeholder='Efternamn' value={sukunimi} onChange={(event) => setSukunimi(event.target.value)} />}
                                {language === 'en' && <input className='hakemus-input sukunimi' placeholder='Lastname' value={sukunimi} onChange={(event) => setSukunimi(event.target.value)} />}
                            </div>
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Sähköposti*</label>}
                            {language === 'sv' && <label className='hakemus-label'>E-post*</label>}
                            {language === 'en' && <label className='hakemus-label'>Email*</label>}
                            <br></br>
                            <input className='hakemus-input' value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Titteli tai oppiarvo</label>}
                            {language === 'sv' && <label className='hakemus-label'>Titel eller lärograd</label>}
                            {language === 'en' && <label className='hakemus-label'>Title or education</label>}
                            <br></br>
                            <input className='hakemus-input' value={titteli} onChange={(event) => setTitteli(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Puhelinnumero*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Telefonnummer*</label>}
                            {language === 'en' && <label className='hakemus-label'>Phone number*</label>}
                            <br></br>
                            <input className='hakemus-input' value={puhelinnumero} onChange={(event) => setPuhelinnumero(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Katuosoite*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Adress*</label>}
                            {language === 'en' && <label className='hakemus-label'>Address*</label>}
                            <br></br>
                            <input className='hakemus-input' value={katuosoite} onChange={(event) => setKatuosoite(event.target.value)} />
                            <br></br>
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Postinumero*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Postnummer*</label>}
                            {language === 'en' && <label className='hakemus-label'>Postal code*</label>}
                            <br></br>
                            <input className='hakemus-input' value={postinumero} onChange={(event) => setPostinumero(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Y-tunnus</label>}
                            {language === 'sv' && <label className='hakemus-label'>FO-nummer</label>}
                            {language === 'en' && <label className='hakemus-label'>Business ID</label>}
                            <br></br>
                            <input className='hakemus-input' value={yTunnus} onChange={(event) => setYTunnus(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Syntymävuosi*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Födelseår*</label>}
                            {language === 'en' && <label className='hakemus-label'>Year of Birth*</label>}
                            <br></br>
                            <input className='hakemus-input' value={syntymavuosi} onChange={(event) => setSyntymavuosi(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Maa</label>}
                            {language === 'sv' && <label className='hakemus-label'>Land</label>}
                            {language === 'en' && <label className='hakemus-label'>Country</label>}
                            <br></br>
                            <input className='hakemus-input' value={maa} onChange={(event) => setMaa(event.target.value)} />
                        </div>

                        <div className='hakemus-block'>
                            {language === 'fi' && <label className='hakemus-label'>Paikkakunta*</label>}
                            {language === 'sv' && <label className='hakemus-label'>Ort*</label>}
                            {language === 'en' && <label className='hakemus-label'>Locality*</label>}
                            <br></br>
                            <input className='hakemus-input' value={paikkakunta} onChange={(event) => setPaikkakunta(event.target.value)} />
                        </div>
                        {language === 'fi' && <Button type='Hakemus-Button' text='Lähetä' handleClick={() => haeJaseneksi()} />}
                        {language === 'sv' && <Button type='Hakemus-Button' text='Skicka' handleClick={() => haeJaseneksi()} />}
                        {language === 'en' && <Button type='Hakemus-Button' text='Send' handleClick={() => haeJaseneksi()} />}
                    </form>
                    <footer className='Shadow-footer'></footer>
                </div>
            </div>
        </div>
    )
}

export default JasenHakemus
