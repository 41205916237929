import React, { useEffect, useState } from 'react'
import eventService from '../../services/eventService'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import EventsByYear from '../subComponents/EventsByYear'
import eventsIcon from '../../resources/events-icon.png'

const Tapahtumat = ({ language, eventRedirect }) => {

    const [years, setYears] = useState([])
    const [showYear, setShowYear] = useState(eventRedirect)

    useEffect(() => {
        eventService.getEventYears()
            .then((res) => setYears(res))
    }, [])

    // makes sure the content has loaded before rendering the view
    if (years.length === 0) {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        // when user clicks a spesific year, showYear is passed and only events of that year get fetched & rendered
        if (showYear === '') {
            return (
                <div className='Inner-Container'>
                    <div className='Tapahtumat-container'>
                        <div className='Tapahtumat-heading-div'>
                            {language === 'fi' && <h1 className='Etusivu-title'>Tapahtumat</h1>}
                            {language === 'sv' && <h1 className='Etusivu-title'>Händelser</h1>}
                            {language === 'en' && <h1 className='Etusivu-title'>Events</h1>}
                            <img className='Event-icon' src={eventsIcon} alt='person sepaks to a mic in front of an audience' />
                        </div>
                        <div className='Tapahtumat-years-div'>
                            {years.map((year) => <div key={year} className='Tapahtumat-year-box' onClick={() => setShowYear(year)}><h4>{year}</h4></div>)}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <EventsByYear language={language} year={showYear} setShowYear={setShowYear} />
            )
        }
    }
}

export default Tapahtumat
