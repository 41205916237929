import React from 'react'

const SideDrawer = ({ show, toggleDrawer, setBody, language }) => {

    let drawerClasses = ['Side-Drawer']
    if (show) {
        drawerClasses.push('open')
    }

    const handleSelection = (body) => {
        setBody(body)
        toggleDrawer()
    }

    return (
        <nav className={drawerClasses.join(' ')}>
            <ul>
                {language === 'fi' && <li className='Side-Drawer-item' onClick={() => handleSelection('about')}>Yhdistyksestä</li>}
                {language === 'sv' && <li className='Side-Drawer-item' onClick={() => handleSelection('about')}>Om Föreningen</li>}
                {language === 'en' && <li className='Side-Drawer-item' onClick={() => handleSelection('about')}>About This Association</li>}

                {language === 'fi' && <li className='Side-Drawer-item' onClick={() => handleSelection('publications')}>Julkaisut</li>}
                {language === 'sv' && <li className='Side-Drawer-item' onClick={() => handleSelection('publications')}>Publikationer</li>}
                {language === 'en' && <li className='Side-Drawer-item' onClick={() => handleSelection('publications')}>Publications</li>}

                {language === 'fi' && <li className='Side-Drawer-item' onClick={() => handleSelection('events')}>Tapahtumat</li>}
                {language === 'sv' && <li className='Side-Drawer-item' onClick={() => handleSelection('events')}>Händelser</li>}
                {language === 'en' && <li className='Side-Drawer-item' onClick={() => handleSelection('events')}>Events</li>}

                {language === 'fi' && <li className='Side-Drawer-item' onClick={() => handleSelection('projects')}>Hankkeet</li>}
                {language === 'sv' && <li className='Side-Drawer-item' onClick={() => handleSelection('projects')}>Projekter</li>}
                {language === 'en' && <li className='Side-Drawer-item' onClick={() => handleSelection('projects')}>Projects</li>}
                
                {language === 'fi' && <li className='Side-Drawer-item' onClick={() => handleSelection('publicOpinions')}>Lausunnot</li>}
                {language === 'sv' && <li className='Side-Drawer-item' onClick={() => handleSelection('publicOpinions')}>Utlåtanden</li>}
                {language === 'en' && <li className='Side-Drawer-item' onClick={() => handleSelection('publicOpinions')}>Statements</li>}
            </ul>
        </nav>
    )
}

export default SideDrawer