import React, { useEffect, useState } from 'react'
import yhdistyksestaService from '../../services/yhdistyksestaService'
import Button from '../subComponents/Button'
import loadingAnimation from '../../resources/latausanimaatio.gif'
import twitterLogo from '../../resources/twitter-logo.png'
import facebookLogo from '../../resources/facebook-logo.png'

const Yhdistyksesta = ({ language, setBody }) => {

    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [businessId, setBusinessId] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [board, setBoard] = useState([])
    const [subHeading1, setSubHeading1] = useState('')
    const [chapter1, setChapter1] = useState('')
    const [subHeading2, setSubHeading2] = useState('')
    const [chapter2, setChapter2] = useState('')

    useEffect(() => {
        yhdistyksestaService.getYhdistyksesta(language)
            .then((data) => {
                setAddress(data.address)
                setEmail(data.email)
                setBusinessId(data.business_id)
                setFacebook(data.facebook)
                setTwitter(data.twitter)
                setBoard(data.board.split('/'))
                setSubHeading1(data.sub_heading1)
                setChapter1(data.chapter1)
                setSubHeading2(data.sub_heading2)
                setChapter2(data.chapter2)
            })
    }, [language])

    // makes sure the content has loaded before rendering the view
    if (address === '') {
        return (
            <div className='Inner-Container'>
                <img className='Loading-animation' src={loadingAnimation} alt='Wheel spinning' />
            </div>
        )
    } else {
        return (
            <div className='Yhdistyksesta-container'>
                <div className='Yhdistyksesta-heading-container'>
                    <div>
                        {language === 'fi' && <h1>Yhdistyksestä</h1>}
                        {language === 'sv' && <h1>Om Föreningen</h1>}
                        {language === 'en' && <h1>About This Association</h1>}
                    </div>
                    <div className='Yhdistyksesta-heading-grid'>
                        <img className='Yhdistyksesta-social-logo Yhdistyksesta-heading-grid-item' src={twitterLogo} alt='twitter' />
                        <p className='Yhdistyksesta-heading-grid-item'>{twitter}</p>
                        <img className='Yhdistyksesta-social-logo Yhdistyksesta-heading-grid-item' src={facebookLogo} alt='facebook' />
                        <p className='Yhdistyksesta-heading-grid-item'>{facebook}</p>
                    </div>
                </div>
                <div className='Yhdistyksesta-flex-outer'>
                    <div className='Yhdistyksesta-center-div'>
                        <div className='Yhdistyksesta-center-div-inner'>
                            <h4>{subHeading1}</h4>
                            <p>{chapter1}</p>

                            <h4>{subHeading2}</h4>
                            <p>{chapter2}</p>

                        </div>
                    </div>

                    <div className='Yhdistyksesta-right-div'>
                        <div>
                            {language === 'fi' && <h3>Hallitus:</h3>}
                            {language === 'sv' && <h3>Regering:</h3>}
                            {language === 'en' && <h3>Board Members:</h3>}
                            {board.map((line) => <p key={Math.random()}><b>{line}</b></p>)}
                        </div>
                        <div>
                            {language === 'fi' && <Button type='Yhdistyksesta-hae-jaseneksi-button' text='Hae jäseneksi' handleClick={() => setBody('membershipApplication')} />}
                            {language === 'sv' && <Button type='Yhdistyksesta-hae-jaseneksi-button' text='Ansöka om medlemskap' handleClick={() => setBody('membershipApplication')} />}
                            {language === 'en' && <Button type='Yhdistyksesta-hae-jaseneksi-button' text='Apply for membership' handleClick={() => setBody('membershipApplication')} />}
                        </div>
                    </div>

                    <div className='Yhdistyksesta-left-div'>
                        {language === 'fi' && <h4>Osoite:</h4>}
                        {language === 'sv' && <h4>Adress:</h4>}
                        {language === 'en' && <h4>Address:</h4>}
                        <p>{address}</p>

                        {language === 'fi' && <h4>Sähköposti:</h4>}
                        {language === 'sv' && <h4>E-post:</h4>}
                        {language === 'en' && <h4>Email:</h4>}
                        <p>{email}</p>

                        {language === 'fi' && <h4>Y-tunnus:</h4>}
                        {language === 'sv' && <h4>FO-nummer:</h4>}
                        {language === 'en' && <h4>Business ID:</h4>}
                        <p>{businessId}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Yhdistyksesta
