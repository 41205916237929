import axios from 'axios'

const querystring = require('querystring')

const baseurl = process.env.REACT_APP_BACKEND_URL

const login = async (email, password) => {
    const request = await axios.post(`${baseurl}/extranet/login/`, querystring.stringify({email: email, password: password}))
    return request.data
}

const logout = (cookies) => {
    cookies.remove('user_token')
    cookies.remove('user_identity')
    window.location.href = '/'
}

export default { login, logout }
