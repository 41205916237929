import axios from 'axios'

const baseurl = process.env.REACT_APP_BACKEND_URL

const getEtusivu = (language) => {
    const request = axios.get(`${baseurl}/frontpages/${language}`)
    return request.then((res) => res.data)
}

export default { getEtusivu }
