import React from 'react'

const ProjectFiles = ({ projectFiles }) => {

  const baseurl = process.env.REACT_APP_BACKEND_URL

  return (
    <>
      {projectFiles.map((filename) => <p key={filename}><a className='Project-filename' href={`${baseurl}/projects/file/${filename}/`}>{filename}</a></p>)}
    </>
  )
}

export default ProjectFiles
