import React, { useEffect, useState } from 'react'
import memberService from '../../services/memberService'
import editIcon from '../../resources/edit.png'

const OmatTiedot = ({ cookies, language, setBody }) => {

    const [email, setEmail] = useState('')
    const [sukunimi, setSukunimi] = useState('')
    const [etunimet, setEtunimet] = useState('')
    const [titteli, setTitteli] = useState('')
    const [puhelinnumero, setPuhelinnumero] = useState('')
    const [katuosoite, setKatuosoite] = useState('')
    const [postinumero, setPostinumero] = useState('')
    const [yTunnus, setYTunnus] = useState('')
    const [syntymavuosi, setSyntymavuosi] = useState('')
    const [maa, setMaa] = useState('')
    const [paikkakunta, setPaikkakunta] = useState('')

    const userIdentity = cookies.get('user_identity')
    const userToken = cookies.get('user_token')

    useEffect(() => {
        memberService.getDetails(userIdentity, userToken)
            .then((res) => {
                setEmail(res.email)
                setSukunimi(res.last_name)
                setEtunimet(res.first_names)
                setTitteli(res.title)
                setPuhelinnumero(res.phone_number)
                setKatuosoite(res.address)
                setPostinumero(res.postal_code)
                setYTunnus(res.business_id)
                setSyntymavuosi(res.birthyear)
                setMaa(res.country)
                setPaikkakunta(res.city)
            })
    }, [userIdentity, userToken])

    const editDetails = () => {
        setBody('account/edit')
    }

    return (
        <div className='Inner-Container'>
            <div className='Omat-tiedot-container-outer'>
                <div className='Omat-tiedot-container-inner'>

                    {language === 'fi' && <h1 className='Omat-tiedot-header'>Omat tiedot</h1>}
                    {language === 'sv' && <h1 className='Omat-tiedot-header'>Egen information</h1>}
                    {language === 'en' && <h1 className='Omat-tiedot-header'>My information</h1>}

                    {language === 'fi' && <img className='Edit-icon' src={editIcon} alt='muokkaa' onClick={() => editDetails()} />}
                    {language === 'sv' && <img className='Edit-icon' src={editIcon} alt='redigera' onClick={() => editDetails()} />}
                    {language === 'en' && <img className='Edit-icon' src={editIcon} alt='edit' onClick={() => editDetails()} />}

                    {email !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Sähköposti</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>E-post</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Email</label>}
                        <p className='Omat-tiedot-item'>{email}</p>
                    </div>}

                    {sukunimi !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Sukunimi</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Efternamn</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Last name</label>}
                        <p className='Omat-tiedot-item'>{sukunimi}</p>
                    </div>}

                    {etunimet !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Etunimet</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Förnamn</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>First names</label>}
                        <p className='Omat-tiedot-item'>{etunimet}</p>
                    </div>}

                    {titteli !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Titteli tai oppiarvo</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Titel eller lärograd</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Title or education</label>}
                        <p className='Omat-tiedot-item'>{titteli}</p>
                    </div>}

                    {puhelinnumero !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Puhelinnumero</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Telefonnummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Phone number</label>}
                        <p className='Omat-tiedot-item'>{puhelinnumero}</p>
                    </div>}

                    {katuosoite !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Katuosoite</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Adress</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Address</label>}
                        <p className='Omat-tiedot-item'>{katuosoite}</p>
                    </div>}

                    {postinumero !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Postinumero</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Postnummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Zip code</label>}
                        <p className='Omat-tiedot-item'>{postinumero}</p>
                    </div>}

                    {yTunnus !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Y-tunnus</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>FO-nummer</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Business ID</label>}
                        <p className='Omat-tiedot-item'>{yTunnus}</p>
                    </div>}

                    {syntymavuosi !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Syntymävuosi</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Födelseår</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Year of birth</label>}
                        <p className='Omat-tiedot-item'>{syntymavuosi}</p>
                    </div>}

                    {maa !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Maa</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Land</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Country</label>}
                        <p className='Omat-tiedot-item'>{maa}</p>
                    </div>}

                    {paikkakunta !== '' && <div className='Omat-tiedot-block'>
                        {language === 'fi' && <label className='Omat-tiedot-label'>Paikkakunta</label>}
                        {language === 'sv' && <label className='Omat-tiedot-label'>Ort</label>}
                        {language === 'en' && <label className='Omat-tiedot-label'>Locality</label>}
                        <p className='Omat-tiedot-item'>{paikkakunta}</p>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default OmatTiedot
