import axios from 'axios'

const baseurl = process.env.REACT_APP_BACKEND_URL

const getCategories = () => {
  const request = axios.get(`${baseurl}/publications/categories/`)
  return request.then((res) => res.data)
}

const getPublicationsByCategory = (category) => {
  const request = axios.get(`${baseurl}/publications/${category}/`)
  return request.then((res) => res.data)
}

const getPublication = (name) => {
  const request = axios.get(`${baseurl}/publications/names/${name}/`)
  return request.then((res) => res.data)
}

const getNewestPublication = () => {
  const request = axios.get(`${baseurl}/publications/newest/`)
  return request.then((res) => res.data)
}

export default { getCategories, getPublicationsByCategory, getPublication, getNewestPublication }
