import React from 'react'

const PublicOpinionList = ({ publicOpinions, handlePublicOpinionSelection }) => {
  return (
    <>
      {publicOpinions.map((title) => <div key={title} className='PublicOpinionList-box' onClick={() => handlePublicOpinionSelection(title)}><p>{title}</p></div>)}
    </>
  )
}

export default PublicOpinionList
