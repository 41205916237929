import React, { useEffect, useState } from 'react'
import eventService from '../../services/eventService'
import eventsIcon from '../../resources/events-icon.png'

const NewestEvent = ({ language, setBody, setEventRedirect }) => {

  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    eventService.getNewestEvent(language)
      .then((data) => {
        setDate(data.date)
        setTime(data.time)
        setName(data.name)
      })
  }, [language])

  const handleClick = () => {
    setBody('events')
    setEventRedirect(date.split('.')[2]) // view events by the event's year
  }

  return (
    <div className='NewestEvent' onClick={() => handleClick()}>
      <img className='NewestEvent-icon' src={eventsIcon} alt='eventful icon' />
      <h2>{name}</h2>
      {language === 'fi' && <p className='Event-date'>{date} klo {time}</p>}
      {language === 'sv' && <p className='Event-date'>{date} klockan {time}</p>}
      {language === 'en' && <p className='Event-date'>{date} at {time}</p>}
    </div>
  )
}

export default NewestEvent
