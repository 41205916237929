import React from 'react'
import logo from '../../../resources/logo512.png'
import DrawerToggleButton from './DrawerToggleButton'

const LowerNavBar = ({ toggleDrawer, setBody, language }) => {
    return (
        <header className='LowerNavBar'>
            <nav className='LowerNavBar-navigation'>
                <div className='LowerNavBar-navigation'>
                    <DrawerToggleButton toggleDrawer={toggleDrawer} />
                </div>
                <img className='LowerNavBar-logo' src={logo} alt='Liikennesuunnittelun Seura' onClick={() => setBody('main')} />
                {language === 'fi' && <h4 className='LowerNavBar-LSS' onClick={() => setBody('main')}>Liikennesuunnittelun Seura ry</h4>}
                {language === 'sv' && <h4 className='LowerNavBar-LSS' onClick={() => setBody('main')}>Trafikplaneringsföreningen i Finland rf</h4>}
                {language === 'en' && <h4 className='LowerNavBar-LSS' onClick={() => setBody('main')}>The Finnish Traffic and Transportation Planning Association</h4>}
                <div className='Spacer'></div>
                <div className='LowerNavBar-items'>
                    <ul>
                        {language === 'fi' && <li className='LowerNavBar-item' onClick={() => setBody('about')}>Yhdistyksestä</li>}
                        {language === 'sv' && <li className='LowerNavBar-item' onClick={() => setBody('about')}>Om Föreningen</li>}
                        {language === 'en' && <li className='LowerNavBar-item' onClick={() => setBody('about')}>About This Association</li>}

                        {language === 'fi' && <li className='LowerNavBar-item' onClick={() => setBody('publications')}>Julkaisut</li>}
                        {language === 'sv' && <li className='LowerNavBar-item' onClick={() => setBody('publications')}>Publikationer</li>}
                        {language === 'en' && <li className='LowerNavBar-item' onClick={() => setBody('publications')}>Publications</li>}
                        
                        {language === 'fi' && <li className='LowerNavBar-item' onClick={() => setBody('events')}>Tapahtumat</li>}
                        {language === 'sv' && <li className='LowerNavBar-item' onClick={() => setBody('events')}>Händelser</li>}
                        {language === 'en' && <li className='LowerNavBar-item' onClick={() => setBody('events')}>Events</li>}
                        
                        {language === 'fi' && <li className='LowerNavBar-item' onClick={() => setBody('projects')}>Hankkeet</li>}
                        {language === 'sv' && <li className='LowerNavBar-item' onClick={() => setBody('projects')}>Projekter</li>}
                        {language === 'en' && <li className='LowerNavBar-item' onClick={() => setBody('projects')}>Projects</li>}
                        
                        {language === 'fi' && <li className='LowerNavBar-item' onClick={() => setBody('publicOpinions')}>Lausunnot</li>}
                        {language === 'sv' && <li className='LowerNavBar-item' onClick={() => setBody('publicOpinions')}>Utlåtanden</li>}
                        {language === 'en' && <li className='LowerNavBar-item' onClick={() => setBody('publicOpinions')}>Statements</li>}
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default LowerNavBar
