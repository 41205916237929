import axios from 'axios'

const querystring = require('querystring')

const baseurl = process.env.REACT_APP_BACKEND_URL

const validateMembershipForm = (language, email, sukunimi, etunimet, titteli, puhelinnumero,
    katuosoite, postinumero, yTunnus, syntymavuosi, maa, paikkakunta) => {

    // sähköposti
    if (email.length === 0) {
        if (language === 'fi') {
            return 'Sähköposti on pakollinen.'
        }
        if (language === 'sv') {
            return 'E-post krävs.'
        }
        if (language === 'en') {
            return 'Email is mandatory.'
        }
    }
    if (email.length > 50) {
        if (language === 'fi') {
            return 'Sähköposti on liian pitkä.'
        }
        if (language === 'sv') {
            return 'E-postadressen är för lång.'
        }
        if (language === 'en') {
            return 'Email is too long.'
        }
    }
    if (!email.includes('@')) {
        if (language === 'fi') {
            return 'Tarkista sähköposti.'
        }
        if (language === 'sv') {
            return 'Kontrollera e-postadressen.'
        }
        if (language === 'en') {
            return 'Check the email address.'
        }
    }

    // sukunimi
    if (sukunimi.length === 0) {
        if (language === 'fi') {
            return 'Sukunimi on pakollinen.'
        }
        if (language === 'sv') {
            return 'Efternamn krävs.'
        }
        if (language === 'en') {
            return 'Last name is mandatory.'
        }
    }
    if (sukunimi.length > 100) {
        if (language === 'fi') {
            return 'Sukunimi on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Efternamn är för långt.'
        }
        if (language === 'en') {
            return 'Last name is too long.'
        }
    }

    // etunimet
    if (etunimet.length === 0) {
        if (language === 'fi') {
            return 'Etunimi on pakollinen.'
        }
        if (language === 'sv') {
            return 'Förnamn krävs.'
        }
        if (language === 'en') {
            return 'First name is mandatory.'
        }
    }
    if (etunimet.length > 100) {
        if (language === 'fi') {
            return 'Etunimi on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Förnamnet är för långt.'
        }
        if (language === 'en') {
            return 'First name is too long.'
        }
    }

    // titteli tai oppiarvo
    if (titteli.length > 50) {
        if (language === 'fi') {
            return 'Titteli tai oppiarvo on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Titeln eller lärograd är för lång.'
        }
        if (language === 'en') {
            return 'Title or education is too long.'
        }
    }

    // puhelinnumero
    if (puhelinnumero.length === 0) {
        if (language === 'fi') {
            return 'Puhelinnumero on pakollinen.'
        }
        if (language === 'sv') {
            return 'Telefonnummer krävs.'
        }
        if (language === 'en') {
            return 'Phone number is mandatory.'
        }
    }
    if (puhelinnumero.length > 40) {
        if (language === 'fi') {
            return 'Puhelinnumero on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Telefonnummer är för långt.'
        }
        if (language === 'en') {
            return 'Phone number is too long.'
        }
    }

    // katuosoite
    if (katuosoite.length === 0) {
        if (language === 'fi') {
            return 'Katuosoite on pakollinen.'
        }
        if (language === 'sv') {
            return 'Adress krävs.'
        }
        if (language === 'en') {
            return 'Street address is mandatory.'
        }
    }
    if (katuosoite.length > 50) {
        if (language === 'fi') {
            return 'Katuosoite on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Adress är för lång.'
        }
        if (language === 'en') {
            return 'Street address is too long.'
        }
    }

    // postinumero
    if (postinumero.length === 0) {
        if (language === 'fi') {
            return 'Postinumero on pakollinen.'
        }
        if (language === 'sv') {
            return 'Postnummer krävs.'
        }
        if (language === 'en') {
            return 'Postal code is mandatory.'
        }
    }
    if (postinumero.length !== 5) {
        if (language === 'fi') {
            return 'Tarkista postinumero.'
        }
        if (language === 'sv') {
            return 'Kontrollera postnummer.'
        }
        if (language === 'en') {
            return 'Check the postal code.'
        }
    }
    if (!Number.isInteger(Number(postinumero))) {
        if (language === 'fi') {
            return 'Tarkista postinumero.'
        }
        if (language === 'sv') {
            return 'Kontrollera postnummer.'
        }
        if (language === 'en') {
            return 'Check the postal code.'
        }
    }

    // y-tunnus
    if (yTunnus.length > 0) {
        if (yTunnus.length !== 9) {
            if (language === 'fi') {
                return 'Tarkista y-tunnus.'
            }
            if (language === 'sv') {
                return 'Kontrollera affärs-ID.'
            }
            if (language === 'en') {
                return 'Check the business ID.'
            }
        }
    }

    // syntymävuosi
    if (syntymavuosi.length === 0) {
        if (language === 'fi') {
            return 'Syntymävuosi on pakollinen.'
        }
        if (language === 'sv') {
            return 'Födelseår krävs.'
        }
        if (language === 'en') {
            return 'Year of birth is mandatory.'
        }
    }
    if (syntymavuosi.length > 4) {
        if (language === 'fi') {
            return 'Tarkista syntymävuosi.'
        }
        if (language === 'sv') {
            return 'Kontrollera födelseår.'
        }
        if (language === 'en') {
            return 'Check the birthyear.'
        }
    }

    // maa
    if (maa.length > 50) {
        if (language === 'fi') {
            return 'Maa on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Landet är för långt.'
        }
        if (language === 'en') {
            return 'Country is too long.'
        }
    }

    // paikkakunta
    if (paikkakunta.length === 0) {
        if (language === 'fi') {
            return 'Paikkakunta on pakollinen.'
        }
        if (language === 'sv') {
            return 'Ort krävs.'
        }
        if (language === 'en') {
            return 'Locality is mandatory.'
        }
    }
    if (paikkakunta.length > 50) {
        if (language === 'fi') {
            return 'Paikkakunta on liian pitkä.'
        }
        if (language === 'sv') {
            return 'Ort är för lång.'
        }
        if (language === 'en') {
            return 'Locality is too long.'
        }
    }

    return 'success'
}

const sendMemberApplication = (language, email, sukunimi, etunimet, titteli, puhelinnumero, katuosoite,
    postinumero, yTunnus, syntymavuosi, maa, paikkakunta) => {
    const request = axios.post(`${baseurl}/extranet/members/application`, querystring.stringify({
        language: language,
        email: email,
        last_name: sukunimi,
        first_names: etunimet,
        title: titteli,
        phone_number: puhelinnumero,
        address: katuosoite,
        postal_code: postinumero,
        business_id: yTunnus,
        birthyear: syntymavuosi,
        country: maa,
        city: paikkakunta
    }))
    return request.then((res) => res.data)
}

export default { validateMembershipForm, sendMemberApplication }
