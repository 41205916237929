import React, { useState } from 'react'
import UpperNavBar from '../../subComponents/navbar/UpperNavBar'
import LowerNavBar from '../../subComponents/navbar/LowerNavBar'
import SideDrawer from '../../subComponents/navbar/SideDrawer'
import Backdrop from '../../subComponents/navbar/Backdrop'

const Header = ({ setBody, cookies, language, changeLanguage }) => {

    const [drawerOpen, setDrawerOpen] = useState(false)

    const toggleDrawer = () => {
        drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true)
    }

    return (
        <div>
            <UpperNavBar setBody={setBody} cookies={cookies} language={language} changeLanguage={changeLanguage} />
            <LowerNavBar toggleDrawer={toggleDrawer} setBody={setBody} language={language} />
            <SideDrawer show={drawerOpen} toggleDrawer={toggleDrawer} setBody={setBody} language={language} />
            {drawerOpen && <Backdrop toggleDrawer={toggleDrawer} />}
        </div>
    )
}

export default Header
